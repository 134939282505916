<template>
  <v-layout class="fill-height overflow-y-auto" column align-center justify-center>
    <v-container class="pa-0" style="max-width: 600px; background-color: #7a78e6">
      <v-card>
        <v-card-title>
        </v-card-title>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>
